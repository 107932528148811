import firebaseApp from '@/app/database/firebase-app';
import { YoutubeTranscriptBlock } from '@/app/types/transcript/YoutubeTranscriptBlock';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const getVideoTranscript = async (videoId: string) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.get<YoutubeTranscriptBlock[]>(
    process.env.NEXT_PUBLIC_GET_YOUTUBE_TRANSCRIPT_FUNCTION_URL,
    {
      timeout: 0,
      params: {
        videoId
      }
    }
  );

  return response.data;
};

const getVideoTitle = async (videoId: string) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.get<string>('/api/youtube/title', {
    timeout: 0,
    params: {
      videoId
    }
  });

  return response.data;
};

export default {
  getVideoTranscript,
  getVideoTitle
};
