import React, { useState } from 'react';
import WikipediaSearch from '../wikipedia-search/WikipediaSearch';
import TranscriptAPI from '@/app/api/YoutubeAPI/YoutubeAPI';
import { useFeedback } from '@/app/context/FeedbackContext/useFeedback';
import Loader from '../loader/Loader';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import Image from 'next/legacy/image';

interface IProps {
  disabled: boolean;
  onChange: (title: string, file: File, videoId: string) => void;
  isPreview: boolean;
  isYoutube?: boolean;
  isWikipedia?: boolean;
}

const AlternateOptions = (props: IProps) => {
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [language, setLanguage] = useState({ value: 'en', label: 'en' });
  const [showYoutube, setShowYoutube] = useState(false);
  const [showYoutubeError, setShowYoutubeError] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [youtubeLoading, setYoutubeLoading] = useState(false);
  const [showWikipedia, setShowWikipedia] = useState(false);

  const { submitFeedback } = useFeedback();

  const { user } = useAuth();

  const onYoutubeClick = () => {
    setShowYoutube(true);
  };

  const hideYoutube = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }

    setShowYoutubeError(false);
    setYoutubeUrl('');
    setYoutubeLoading(false);
    setShowYoutube(false);
  };

  const hideWikipedia = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }

    setShowWikipedia(false);
  };

  const getYouTubeVideoId = (url: string): string => {
    const regex = /youtu\.be\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
    const match = url.match(regex);
    return match ? match[1] : '';
  };

  const onYoutubeUrlChange = async (e) => {
    let videoId = null;

    let value = ((e?.currentTarget?.value as string) || '').trim();

    setYoutubeUrl(value);

    try {
      videoId = new URL(value)?.searchParams?.get('v');
    } catch {}

    if (!videoId) {
      try {
        videoId = getYouTubeVideoId(value);
      } catch {}
    }

    // Try get id from youtu.be link

    if (!videoId || videoId.length !== 11) {
      setShowYoutubeError(true);
    } else {
      try {
        setYoutubeLoading(true);

        const [title, transcript] = await Promise.all([
          TranscriptAPI.getVideoTitle(videoId),
          TranscriptAPI.getVideoTranscript(videoId)
        ]);

        const concatenatedText = transcript
          .map((t) => t.text)
          .join(' ')
          .replace(/\n/g, ' ');

        var file = new File([concatenatedText], title + '.txt', {
          type: 'text/plain'
        });

        props.onChange(title, file, videoId);

        hideYoutube();

        try {
          submitFeedback(
            `${!user.isAnonymous ? 'authed' : 'anon'}` +
              ' : Youtube paste success: ' +
              videoId +
              ' : ' +
              title
          );
        } catch {}
      } catch (error) {
        console.log(error);
        submitFeedback('Youtube video failed: ' + videoId);

        window.alert(
          'Something went wrong. Please verify the video is publicly available and subtitles are available for the video. Otherwise, this issue will be investigated and please try again later.'
        );
        hideYoutube();
      }
    }
  };

  const onWikipediaClick = () => {
    setShowWikipedia(true);
  };

  if (youtubeLoading) {
    return (
      <div className="flex items-center">
        <Loader /> Loading video...
      </div>
    );
  }

  if (showYoutube || props.isYoutube) {
    return (
      <div className="w-full">
        <div className="flex w-full items-center gap-2">
          {!props.isYoutube && (
            <div
              className="cursor-pointer"
              id="hide-yt-element"
              onClick={hideYoutube}
              // onTouchStart={hideYoutube}
              // onTouchEnd={hideYoutube}
              // onTouchEndCapture={hideYoutube}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
            </div>
          )}

          <input
            name={'youtube link'}
            className={`focus:shadow-outline flex-1 appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none`}
            disabled={youtubeLoading}
            value={youtubeUrl}
            onChange={onYoutubeUrlChange}
            placeholder="Paste YouTube link"
          />
        </div>
        {showYoutubeError && (
          <div className="justify-right float-right mt-1 flex content-center items-center space-x-2 align-middle">
            <div className="text-xs text-red">Link missing video id</div>
          </div>
        )}
      </div>
    );
  }

  if (showWikipedia || props.isWikipedia) {
    return (
      <div className="flex w-full items-center justify-center gap-2">
        {!props.isWikipedia && (
          <div className="cursor-pointer" onClick={hideWikipedia}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              className="inline-block h-6 w-6 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              ></path>
            </svg>
          </div>
        )}

        <WikipediaSearch disabled={props.disabled} onChange={props.onChange} />
      </div>
    );
  }

  return (
    <div
      className={`${props.disabled ? 'pointer-events-none opacity-60' : ''} ${
        props.isPreview ? 'pl-0 pr-0 md:pl-6 md:pr-6' : ''
      } flex w-full items-center justify-center gap-4 lg:ml-0 lg:justify-start`}
    >
      <button
        id="show-yt-element"
        onClick={() =>
          showYoutube || props.disabled ? null : onYoutubeClick()
        }
        className={`inline-flex flex-1 transform cursor-pointer items-center justify-center gap-2 rounded bg-neutral px-4 py-2 font-bold text-neutral-content transition duration-300 ease-in-out hover:scale-105 hover:underline`}
      >
        <svg
          className="y-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M19.6069 6.99482C19.5307 6.69695 19.3152 6.47221 19.0684 6.40288C18.6299 6.28062 16.501 6 12.001 6C7.50098 6 5.37252 6.28073 4.93225 6.40323C4.68776 6.47123 4.4723 6.69593 4.3951 6.99482C4.2863 7.41923 4.00098 9.19595 4.00098 12C4.00098 14.804 4.2863 16.5808 4.3954 17.0064C4.47126 17.3031 4.68676 17.5278 4.93251 17.5968C5.37252 17.7193 7.50098 18 12.001 18C16.501 18 18.6299 17.7194 19.0697 17.5968C19.3142 17.5288 19.5297 17.3041 19.6069 17.0052C19.7157 16.5808 20.001 14.8 20.001 12C20.001 9.2 19.7157 7.41923 19.6069 6.99482ZM21.5442 6.49818C22.001 8.28 22.001 12 22.001 12C22.001 12 22.001 15.72 21.5442 17.5018C21.2897 18.4873 20.547 19.2618 19.6056 19.5236C17.8971 20 12.001 20 12.001 20C12.001 20 6.10837 20 4.39637 19.5236C3.45146 19.2582 2.70879 18.4836 2.45774 17.5018C2.00098 15.72 2.00098 12 2.00098 12C2.00098 12 2.00098 8.28 2.45774 6.49818C2.71227 5.51273 3.45495 4.73818 4.39637 4.47636C6.10837 4 12.001 4 12.001 4C12.001 4 17.8971 4 19.6056 4.47636C20.5505 4.74182 21.2932 5.51636 21.5442 6.49818ZM10.001 15.5V8.5L16.001 12L10.001 15.5Z"
          ></path>
        </svg>
        <span>YouTube</span>
      </button>
      <button
        onClick={() =>
          showWikipedia || props.disabled ? null : onWikipediaClick()
        }
        className={` bg-grey inline-flex flex-1 transform cursor-pointer items-center justify-center gap-2 rounded bg-neutral px-4 py-2 font-bold text-neutral-content transition duration-300 ease-in-out hover:scale-105 hover:underline`}
      >
        <Image
          height={20}
          width={20}
          alt="wikipedia"
          src="/wikipedia_logo.png"
        />
        <span>Wikipedia</span>
      </button>
    </div>
  );
};

export default AlternateOptions;
