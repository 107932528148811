import WikipediaAPI from '@/app/api/WikipediaAPI/WikipediaAPI';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCombobox from '../async-combobox/AsyncCombobox';

const WikipediaSearch = ({ disabled, onChange, isPreview = false }) => {
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [language, setLanguage] = useState({ value: 'en', label: 'en' });

  const inputRef = React.useRef();

  const defaultOptions = {
    ca: [
      'Fibril·lació auricular',
      'Torre Eiffel',
      'Taxa de filtració glomerular',
      'La gran Muralla Xina',
      'Mitocondri'
    ],
    da: ['Atrieflimren', 'Eiffeltårnet', 'Den kinesiske Mur', 'Mitokondrie'],
    de: [
      'Vorhofflimmern',
      'Eiffelturm',
      'Glomeruläre Filtrationsrate',
      'Chinesische Mauer',
      'Mitochondrium'
    ],
    el: ['Κολπική μαρμαρυγή', 'Πύργος του Άιφελ'],
    en: [
      'Atrial fibrillation',
      'Eiffel Tower',
      'Glomerular filtration rate',
      'Great Wall of China',
      'Mitochondrion'
    ],
    es: [
      'Fibrilación auricular',
      'Torre Eiffel',
      'tasa de filtración glomerular',
      'Mitocondria'
    ],
    fi: ['Eteisvärinä', 'Eiffel torni', 'Kiinan muuri', 'Mitokondrio'],
    fr: [
      'Fibrillation auriculaire',
      'Tour Eiffel',
      'Taux de filtration glomérulaire',
      'La Grande Muraille de Chine',
      'Mitochondrie'
    ],
    hr: ['Eiffelov toranj', 'Kineski zid', 'Mitohondrij'],
    it: [
      'Fibrillazione atriale',
      'Torre Eiffel',
      'Velocità di filtrazione glomerulare',
      'Grande Muraglia cinese',
      'Mitocondrio'
    ],
    ja: [
      '心房細動',
      'エッフェル塔',
      '糸球体濾過率',
      '万里の長城',
      'ミトコンドリア'
    ],
    ko: ['심방세동', '에펠탑', '사구체여과율', '만리장성', '미토콘드리아'],
    lt: ['Eifelio bokštas', 'Didžioji Kinijos siena', 'Mitochondrija'],
    mk: ['Атријална фибрилација', 'Митохондрија'],
    nl: [
      'Boezemfibrilleren',
      'Eiffeltoren',
      'Glomerulaire filtratiesnelheid',
      'Grote muur van China',
      'Mitochondrion'
    ],
    pl: [
      'Migotanie przedsionków',
      'Wieża Eiffla',
      'Wielki Mur Chiński',
      'Mitochondrium'
    ],
    pt: [
      'Fibrilação atrial',
      'Torre Eiffel',
      'Taxa de filtração glomerular',
      'Muralha da China',
      'Mitocôndria'
    ],
    ro: [
      'Fibrilatie atriala',
      'Turnul Eiffel',
      'Marele Zid Chinezesc',
      'Mitocondrie'
    ],
    ru: [
      'Мерцательная аритмия',
      'Эйфелева башня',
      'Скорость клубочковой фильтрации',
      'Великая китайская стена',
      'Митохондрия'
    ],
    sv: [
      'Förmaksflimmer',
      'Eiffeltornet',
      'Glomerulär filtrationshastighet',
      'Kinesiska muren',
      'Mitokondrie'
    ],
    uk: [
      'Миготлива аритмія',
      'Ейфелева вежа',
      'Швидкість клубочкової фільтрації',
      'Велика Китайська стіна',
      'Мітохондрія'
    ],
    zh: ['心房顫動', '七年戰爭', '線粒體']
  };

  const languageOptions = [
    'ca',
    'da',
    'de',
    'el',
    'en',
    'es',
    'fi',
    'fr',
    'hr',
    'it',
    'ja',
    'ko',
    'lt',
    'mk',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'sv',
    'uk',
    'zh'
  ];

  const promiseOptions = async (inputValue: string) => {
    const searchResults = await WikipediaAPI.search(inputValue, language.value);
    return searchResults.map((t) => ({ value: t, label: t }));
  };

  const onChangeLanguage = (value: any) => {
    setInputValue('');
    setLanguage(value);
  };

  const onSelection = async (value: any) => {
    if (value?.value) {
      setValue(value);
      const name = value.value;

      setIsLoadingArticle(true);

      const articleText = await WikipediaAPI.article(name, language.value);
      const deckName = name;

      var file = new File([articleText], deckName + '.txt', {
        type: 'text/plain'
      });

      onChange(deckName, file);

      setIsLoadingArticle(false);
      setInputValue('');
    }

    setValue(null);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: 'none'
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'left'
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left'
    })
  };

  return (
    <div className="flex w-full items-center text-black">
      <div style={{ flex: 1 }}>
        <AsyncSelect
          ref={inputRef}
          isDisabled={isLoadingArticle || disabled}
          aria-label="Enter a wikipedia article"
          styles={customStyles}
          placeholder={`${isPreview ? 'Try' : 'Enter'} a wikipedia article...`}
          cacheOptions
          defaultOptions={defaultOptions[language.value].map((t) => ({
            value: t,
            label: t
          }))}
          classNamePrefix="react-select"
          id="long-value-select"
          instanceId="long-value-select"
          isClearable={true}
          loadOptions={promiseOptions}
          value={value}
          inputValue={inputValue}
          onInputChange={(newInput) => setInputValue(newInput)}
          onChange={(newValue) => onSelection(newValue)}
        />
        {/* 
        <AsyncCombobox /> */}
      </div>

      {/* <div style={{ width: 80 }}>
        <Select
          value={language}
          onChange={onChangeLanguage}
          options={languageOptions.map((t) => ({ value: t, label: t }))}
        />
      </div> */}
    </div>
  );
};

export default WikipediaSearch;
