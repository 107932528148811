import React from 'react';

const Progress = ({ percent }) => {
  return (
    <div className="relative w-full pt-1">
      <div className="mb-4 flex h-2 overflow-hidden rounded bg-white text-xs">
        <div
          style={{ width: `${percent}%` }}
          className="flex flex-col justify-center whitespace-nowrap bg-primary text-center text-base-content shadow-none"
        ></div>
      </div>
    </div>
  );
};

export default Progress;
