import axios from 'axios';

// GET - Gets an upload url for the deck id
const search = async (name: string, language: string) => {
  return await (
    await axios.get<string[]>('/api/wikipedia/search', {
      params: { name, language }
    })
  ).data;
};

const article = async (name: string, language: string) => {
  return await (
    await axios.get<string>('/api/wikipedia/article', {
      params: { name, language }
    })
  ).data;
};

export default {
  search,
  article
};
